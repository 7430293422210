import api from "../../admin/infra/api";
import { useQuery } from "react-query";
import { VacancyType } from "../../users/types/user";

const endpoint = process.env.REACT_APP_API_URL + `api/vacancy/avaliable`;
const updateEndpoint = process.env.REACT_APP_API_URL + `api/vacancy`;

const getAvalibleSys =
  process.env.REACT_APP_API_URL + `api/vacancy/avaliableSYS`;
const getFilter = process.env.REACT_APP_API_URL + `api/vacancy/filter`;
const getWebCanUrl = process.env.REACT_APP_API_URL + `api/vacancy/web`;
export type AgendasType = {
  id: number;
  type: number;
  user: number;
  hisid: number;
  title: string;
  comment: string;
  codchave: number;
  contact_id: any;
  event_type: number;
  start_date: number;
  event_situation: number;
};
export type HisVacancyCan = {
  codigo: number;
  vaga: number;
  agendas: AgendasType[];
  candidato: number;
  chamado: number;
  chamado_texto: any;
  dtchamado: string;
  atendente: any;
  veio: number;
  dtveio: any;
  enviado: number;
  dtenviado: string;
  contratado: any;
  dtcontratado: any;
  gerente: any;
  salario: any;
  emailsel: any;
  emailenc: any;
  aprovado: number;
  compcli: number;
  mobscli: any;
  mnenc: any;
  aprovadocli: number;
  mobscliap: any;
  aprovadoclidata: any;
  solentrevista: number;
  dataent: string;
  horaent: string;
  quement: string;
  solentrevistacanc: number;
  datavisto: any;
  chamadopor: string;
  entrevistadopor: string;
  encaminhadopor: any;
  emanalisecli: any;
  naprovadocli: any;
  dtentrevista: string;
  selecionado_por: string;
  dtselecao: string;
  nome_candidato: string;
  enviadopor: string;
  enviadofonepor: any;
  dtenviadofone: any;
  enviadofone: any;
  dtencerrado: any;
  contato_ok: number;
  veio_direto: any;
  contato_preentre: any;
  contato_recado: any;
  contato_recadotexto: any;
  contato_natende: any;
  contato_email: any;
  contato_stelefone: any;
  contato_sms: any;
  contato_sinteresse: any;
  contato_msinteresse: any;
  contato_trabalhando: any;
  contato_whats: any;
  contato_semperfil: any;
  contato_msemperfil: any;
  contratadodireto: any;
  contratadopor: any;
  contato_standby: any;
  entrevista_pessoal: any;
  entrevista_telefone: number;
  entrevista_video: any;
  entrevista_sinteresse: any;
  entrevista_msinteresse: any;
  entrevista_disistiu: any;
  entrevista_mdisistiu: any;
  entrevista_semperfil: any;
  entrevista_msemperfil: any;
  agenda_geral: any;
  codagenda: any;
  analise_ent_aprov: any;
  analise_ean: any;
  analise_ent_naprov: any;
  analise_ent_teste: any;
  analise_dt_teste: string;
  analise_hora: any;
  analise_obs: any;
  analise_motncomp: any;
  analise_motiivonaprov: any;
  teste_: any;
  teste_dinamica: any;
  teste_psicologico: any;
  teste_hora: any;
  teste_data: any;
  teste_local: any;
  teste_grafologico: any;
  teste_idioma: any;
  teste_disc: any;
  salvopor: string;
  dtsalvoultimo: string;
  dtsalvocli: string;
  emp_ativo_cli: any;
  soneca: number;
  dtstandby: any;
  standbypor: any;
  contratado_por: any;
};

export type VacancyListType = {
  id: number;
  motivo: number;
  contrato: string;
  cidadedavaga: number;
  cargo: string;
  cargo_cli: string;
  data: string;
  vagas: number;
  cargo_nome: string;
  cliente_nome: any;
  cidade: string;
  selecionados: number;
  stand: number;
  enc: number;
  aprov: number;
  contra: number;
  contratadodireto: number;
  entrevistapessoal: number;
  entrevistavideo: number;
  web: number;
  solentrevist: number;
};

async function fetchVacancy(): Promise<undefined | VacancyType[]> {
  try {
    const response = await api(endpoint);

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export function useVacancy() {
  return useQuery("vacancy", () => fetchVacancy());
}

export async function updateVacancy(colum: any, value: any, id: number) {
  try {
    const response = await api(updateEndpoint + `/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        colum: colum,
        value: value,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function updateHisById(
  colum: any,
  value: any,
  id: number | undefined
) {
  try {
    const response = await api(updateEndpoint + `/history/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        colum: colum,
        value: value,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function executeSelection(id: number, body: any) {
  try {
    const response = await api(updateEndpoint + `/selection/${id}`, {
      method: "POST",
      body: JSON.stringify({ body }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function saveSelectionPut(id: number, body: any) {
  try {
    const response = await api(updateEndpoint + `/selection/${id}`, {
      method: "PUT",
      body: JSON.stringify({ body }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function sendCandidateToVacancy(body: any) {
  try {
    const response = await api(updateEndpoint + `/send`, {
      method: "POST",
      body: JSON.stringify({ body }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function removeCandidateFromWeb(body: any) {
  try {
    const response = await api(updateEndpoint + `/web/remove`, {
      method: "POST",
      body: JSON.stringify({ body }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getVacancy(id: number, name: string) {
  try {
    const response = await api(updateEndpoint + `/${id}?name=${name}`);

    const data = await response.text();
    if (data === "") {
      return "";
    }

    if (data === name) {
      return "";
    }

    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getVacancyHistory(id: number): Promise<any[] | boolean> {
  try {
    const response = await api(updateEndpoint + `/history/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getVacancyHistoryByCanId(
  id: number,
  canId: number
): Promise<HisVacancyCan | boolean> {
  try {
    const response = await api(updateEndpoint + `/history/${id}/${canId}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getVacancyInfo(id: number): Promise<any[] | boolean> {
  try {
    const response = await api(updateEndpoint + `/info/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getVacancyInfoAll(id: number): Promise<any[] | boolean> {
  try {
    const response = await api(updateEndpoint + `/info/all/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getVacancySelection(id: number): Promise<any | boolean> {
  try {
    const response = await api(updateEndpoint + `/selection/${id}`);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getVacancyAvaliableSYS(uni?: any): Promise<any> {
  try {
    let newURLAvalibleSys = getAvalibleSys;
    if (uni) {
      newURLAvalibleSys = getAvalibleSys + `?uni=${uni}`;
    }
    const response = await api(newURLAvalibleSys);

    if (response.status >= 203) {
      return undefined;
    }

    console.log(getAvalibleSys);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getPopulateFilter(table: string): Promise<any> {
  try {
    const response = await api(getFilter + `?table=${table}`);

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getWebCandidates(vaga: number): Promise<any> {
  try {
    const response = await api(getWebCanUrl + `?vaga=${vaga}`);

    if (response.status >= 203) {
      return undefined;
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}
